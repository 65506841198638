import { ModelSelect } from 'vue-search-select';
import appStrings from '@/app/utility/string.utility';
import { required } from 'vuelidate/lib/validators';
import commonHelper from '@/app/utility/common.helper.utility';
import {
  getCountryList,
  updateStateData,
  addStateData,
  getStatesByCountryId
} from '@/app/services/api';

export default {
  name: 'State',
  props: {
    showModalState: Boolean,
    formState: String
  },
  components: {
    ModelSelect
  },
  watch: {
    formState: function(update) {
      if (update === 'edit' || update === 'update') {
        this.editMode = true;
      }

      if (update === 'add') {
        this.showModal = true;
        this.editMode = true;
        this.update = false;
      }

      if (update === 'save') {
        this.updateState();
      }
    },
    currentPage: function() {
      this.getFilteredState();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getFilteredState();
    }
  },
  data() {
    return {
      payload:null,
      unsubscribe: null,
      search: '',
      data: [],
      stateId: '',
      defaultCountryId: '',
      stateForm: {
        country: {
          value: '',
          text: ''
        },
        state: '',
        state_tax_code: ''
      },
      stateCountryData: [],
      stateStateData: [],
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      stickyHeader: true,
      totalRows: null,
      filterOn: [],
      showModal: false,
      editMode: false,
      editState: false,
      responseMsg: '',
      isSuccess: false,
      isFailed: false,
      update: false,
      showAlert: false,
      fields: [
        {
          key: 'country',
          sortable: true
        },
        {
          key: 'state',
          sortable: true
        },
        {
          key: 'state_tax_code',
          sortable: false
        }
      ]
    };
  },
  validations: {
    stateForm: {
      country: {
        value: {
          required
        },
        text: {
          required
        }
      },
      state: {
        required
      },
      state_tax_code: {
        required
      }
    }
  },
  methods: {
    async getCountryList() {
      try {
        const response = await getCountryList();
        const results = response.data.data;
        const stateCountry = results.map(type => {
          return {
            value: type.country_id,
            text: type.country
          };
        });
        this.stateCountryData = stateCountry;
        if (
          this.stateCountryData !== null &&
          this.stateCountryData.length > 0
        ) {
          this.defaultCountryId = this.stateCountryData[0].value;
        }
      } catch (err) {
        this.err = err;
      }
    },

    async getFilteredState() {
      this.payload = {
        _page: this.currentPage -1,
        _limit: this.perPage,
        country_id: this.defaultCountryId,
        state_name: this.search
      }
      if (this.defaultCountryId) {
        try {
          const response = await getStatesByCountryId(
            this.defaultCountryId,
            this.currentPage - 1,
            this.perPage,
            this.search
          );
          const results = response.data.data.page;
          this.data = response.data.data.page;
          this.totalRows = response.data.data.total_elements;
          const states = results.map(type => {
            return {
              value: type.state_id,
              text: type.state
            };
          });
          this.stateStateData = states;
        } catch (err) {
          this.err = err;
        }
      }
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
      this.pageOptions[5].value = filteredItems.length;
    },
    resetModal() {
      this.$emit('clicked', 'view');
      this.editMode = false;
      this.showAlert = false;
      this.update = false;
      this.stateForm.state = '';
      this.stateForm.state_tax_code = '';
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    rowSelected: function(item) {
      if (this.$router.currentRoute.name === 'defineState') {
        this.showModal = true;
        this.showAlert = false;
        this.update = true;
        this.stateId = item.state_id;
        this.stateForm.country.text = item.country;
        this.stateForm.country.value = item.country_id;
        this.stateForm.state = item.state;
        this.stateForm.state_tax_code = item.state_tax_code;
      }
      this.$emit('selectedState', item);
    },
    updateState: function() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$emit('clicked', 'edit');
        this.update = false;
        return;
      } else {
        const requestBody = {
          country_id: this.stateForm.country.value,
          state_name: this.stateForm.state,
          state_tax_code: this.stateForm.state_tax_code
        };
        if (this.update) {
          updateStateData(requestBody, this.stateId)
            .then(response => {
              if (response && response.status === 200) {
                this.getCountryList();
                this.showAlert = true;
                this.isSuccess = true;
                this.editMode = false;
                this.update = false;
                this.responseMsg = appStrings.stateSuccessMsg;
              } else {
                this.showAlert = true;
                this.isSuccess = false;
                this.responseMsg = response.response.data.message;
              }
            })
            .catch(err => {
              this.err = err;
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg = appStrings.autoFailedMsg;
            });
        } else {
          addStateData(requestBody)
            .then(response => {
              this.getCountryList();
              if (response && response.status === 201) {
                this.showAlert = true;
                this.isSuccess = true;
                this.editMode = false;
                this.update = false;
                this.responseMsg = appStrings.stateAddedMsg;
              } else {
                this.showAlert = true;
                this.isSuccess = false;
                this.responseMsg = response.response.data.message;
              }
            })
            .catch(err => {
              this.err = err;
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg = appStrings.autoFailedMsg;
            });
        }
      }
    },
    clearStateFields() {
      this.search = '';
      this.stateId = '';
      this.defaultCountryId = '';
      this.data = [];
    },
    resetform() {
      this.stateId = null;
      this.stateForm.country = {
        value: null,
        text: null
      };
      this.stateForm.state = null;
      this.stateForm.state_tax_code = null;
    }
  },
  created() {
    this.getCountryList();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'edit' || actionName === 'update') {
          this.editMode = true;
        }

        if (actionName === 'add') {
          this.resetform();
          this.showAlert = false;
          this.showModal = true;
          this.editMode = true;
          this.update = false;
        }
    
        if (actionName === 'save') {
          this.updateState();
        }
        if (actionName === 'download' && !this.showModal) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'country/getFilteredStateList',
            'state',
            () => (this.loader = false)
          );
        }
        if (actionName === 'upload' && this.showModal) {
          this.eventBus.$emit('commonUpload', { id: this.stateId });
        }
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
